import Typography from 'typography'

const typography = new Typography({
  baseFontSize: '16px',
  baseLineHeight: 1.375,
  scaleRatio: 1.5,
  headerFontFamily: ['Barlow', 'Helvetica', 'Arial', 'sans-serif'],
  headerWeight: 700,
  bodyFontFamily: ['Barlow', 'Helvetica', 'Arial', 'sans-serif'],
  bodyWeight: 'normal'
})

// Export helper functions
export const { scale, rhythm, options } = typography
export default typography
